import {Divider, Flex} from '@chakra-ui/react';
import React from 'react';
import {BiCategory} from 'react-icons/bi';
import {FiArrowDownCircle, FiHome, FiStar, FiThumbsUp, FiUsers, FiYoutube} from 'react-icons/fi';
import {GiDirectorChair} from 'react-icons/gi';
import {ImBarcode} from 'react-icons/im';
import {useToggleSidebarSize} from '../../state/application/hooks';
import {useSessionManager} from '../../state/user/hooks';
import {NavItem} from './NavItem';

export const Sidebar = () => {
  const [navSize] = useToggleSidebarSize();
  const {session} = useSessionManager();

  return (
    <Flex
      sx={{
        position: 'sticky',
        left: 0,
        top: 0,
        justifyContent: 'space-between',
        display: ['none', 'none', 'flex'],
        // height: '100vh',
        width: navSize === 'small' ? '75px' : '240px',
        // pt: '56px',
        flexDirection: ' column',
        bg: 'pink.800',
        zIndex: 'docked',
        // boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05)',
        // overflowY: 'scroll',
      }}
    >
      <Flex
        as="nav"
        sx={{
          flexDirection: 'column',
          width: navSize === 'small' ? '70px' : '206px',
          alignItems: navSize === 'small' ? 'center' : 'flex-start',
          position: 'fixed',
        }}
      >
        <NavItem navSize={navSize} icon={FiHome} title="Home" to="/" />
        <NavItem navSize={navSize} icon={FiYoutube} title="Movies" to="/movies" />
        <NavItem navSize={navSize} icon={FiUsers} title="Actors" to="/actors" />
        <NavItem navSize={navSize} icon={BiCategory} title="Genres" to="/genres" />
        <NavItem navSize={navSize} icon={GiDirectorChair} title="Makers" to="/makers" />
        <NavItem navSize={navSize} icon={ImBarcode} title="Codes" to="/codes" />

        <Divider />

        <NavItem navSize={navSize} icon={FiThumbsUp} title="Like" to="/like" />
        <NavItem navSize={navSize} icon={FiStar} title="Pro" to="/pro" />
        {session && session.user?.isAdmin && (
          <NavItem navSize={navSize} icon={FiArrowDownCircle} title="Torrent" to="/torrents" />
        )}
      </Flex>
    </Flex>
  );
};
