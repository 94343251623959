import {Box, Button, HStack, Portal, Text, useColorModeValue, VStack} from '@chakra-ui/react';
import React from 'react';
import {BiCategory} from 'react-icons/bi';
import {FiHome, FiUsers, FiYoutube} from 'react-icons/fi';
import {Link} from 'react-router-dom';

export const Footer = () => {
  const borderTopColor = useColorModeValue('blackAlpha.300', 'whiteAlpha.300');
  const bg = useColorModeValue('white', 'gray.800');
  return (
    <Portal>
      <Box
        id="footerwrapper"
        sx={{
          left: 0,
          right: 0,
          bottom: 0,
          position: 'fixed',
          width: ['100%'],
          py: [4],
          borderTopWidth: '1px',
          borderTopColor,
          bg,
          display: ['block', 'none'],
          zIndex: 'docked',
        }}
      >
        <HStack justifyContent="space-around">
          <Link to={`/`}>
            <Button variant="ghost">
              <VStack>
                <FiHome />
                <Text>Home</Text>
              </VStack>
            </Button>
          </Link>
          <Link to={`/movies`}>
            <Button variant="ghost">
              <VStack>
                <FiYoutube />
                <Text>Movie</Text>
              </VStack>
            </Button>
          </Link>
          <Link to={`/actors`}>
            <Button variant="ghost">
              <VStack>
                <FiUsers />
                <Text>Actor</Text>
              </VStack>
            </Button>
          </Link>
          <Link to={`/genres`}>
            <Button variant="ghost">
              <VStack>
                <BiCategory />
                <Text>Genre</Text>
              </VStack>
            </Button>
          </Link>
        </HStack>
      </Box>
    </Portal>
  );
};
