import PutioAPI, {IPutioAPIClientResponse} from '@putdotio/api-client';
import axios from 'axios';
import {useCallback, useEffect, useState} from 'react';
import videojs from 'video.js';
import WebVTTConverter from '../lib/webvtt-converter';
import {useSession} from '../state/user/hooks';

export const useQueryPutio = ({
  fileId,
  cid,
}: {
  fileId: string | number;
  cid: string;
}): {
  sources: videojs.Tech.SourceObject[] | undefined;
  tracks: videojs.TextTrackOptions[] | undefined;
  loading: boolean;
} => {
  const [sources, setSources] = useState<videojs.Tech.SourceObject[] | undefined>(undefined);
  const [tracks, setTracks] = useState<videojs.TextTrackOptions[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const session = useSession();

  const cb = useCallback(async () => {
    if (!session || !session.user) return;

    const putClient = new PutioAPI({clientID: parseInt(session.user.putioClientID)});
    putClient.setToken(session.user.putioAccessToken);

    const srcs: videojs.Tech.SourceObject[] | undefined = await putClient.Files.Query(fileId, {
      streamUrlParent: true,
    }).then(({data: {parent}}) => {
      return [
        {
          src: parent.stream_url,
          type: parent.content_type,
        },
      ];
    });

    const textTracks = await putClient.Files.Search(cid, {fileType: 'TEXT', perPage: 10}).then(({data: {files}}) => {
      return Promise.all(
        files
          .filter((file) => file.name.toUpperCase().includes(cid.toUpperCase()) && file.extension === 'srt')
          .map(async (file, i) => {
            let srclang = 'th';
            let label = 'Thai';
            if (file.name.toUpperCase().endsWith('EN.SRT')) {
              srclang = 'en';
              label = 'English';
            }
            const data: IPutioAPIClientResponse<{url: string}> = await putClient.File.GetStorageURL(file.id);
            const url = data.data.url;

            let src;
            if (file.extension === 'vtt') {
              src = url;
            } else if (file.extension === 'srt') {
              const {data} = await axios.get(url);
              const blob = new Blob([data], {type: 'application/octet-stream'});
              const converter = new WebVTTConverter(blob);
              src = await converter.getURL();
            }

            return {
              kind: 'subtitles',
              srclang,
              label,
              src,
              mode: i === 0 ? 'showing' : 'disabled',
            } as videojs.TextTrackOptions;
          })
      ).finally(() => setLoading(false));
    });

    return {sources: srcs, subtitles: textTracks};
  }, [cid, fileId, session]);

  useEffect((): (() => void) => {
    let mounted = true;

    cb().then((data) => {
      if (!mounted || !data) return;

      setSources(data.sources);
      setTracks(data.subtitles);
    });

    return () => (mounted = false);
  }, [cb]);

  return {sources, tracks: tracks, loading};
};
