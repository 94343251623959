import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  LinkBox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import queryString from 'query-string';
import React, {FC, useRef, useState} from 'react';
import {BiCategory} from 'react-icons/bi';
import {
  FiArrowLeft,
  FiHome,
  FiMenu,
  FiMoreVertical,
  FiSearch,
  FiStar,
  FiUser,
  FiUsers,
  FiX,
  FiYoutube,
} from 'react-icons/fi';
import {GiDirectorChair} from 'react-icons/gi';
import {ImBarcode} from 'react-icons/im';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Link, useHistory} from 'react-router-dom';
import {KeyboardEvent} from 'react-router/node_modules/@types/react';
import {AppDispatch} from '../../state';
import {useToggleSidebarSize} from '../../state/application/hooks';
import {updateUserSession} from '../../state/user/actions';
import {useSessionManager} from '../../state/user/hooks';
import {ColorModeSwitcher} from '../ColorMode/ColorModeSwitcher';
import {NavItem} from '../Sidebar/NavItem';

export interface NavbarProps {
  height?: string;
}

export const Navbar: FC<NavbarProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {session} = useSessionManager();
  const history = useHistory();
  const {isOpen: isOpenSettings, onOpen: onOpenSettings, onClose: onCloseSettings} = useDisclosure();
  const {isOpen: isOpenSearch, onOpen: onOpenSearch, onClose: onCloseSearch} = useDisclosure();
  const {isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu} = useDisclosure();
  const [, toggleSidebarSize] = useToggleSidebarSize();

  const btnRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const query = (parsed.query as string) || undefined;
  const [searchQuery, setSearchQuery] = useState(query || '');
  const showToggleSidebar = ['/', '/movies', '/actors', '/makers', '/genres', '/codes', '/pro'].includes(
    location.pathname
  );

  const submitSearch = () => {
    const query = searchQuery.trim();
    if (query.length === 0) return;

    onCloseSearch();

    history.push({pathname: `/movies`, search: queryString.stringify({query})});
  };

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (btnRef?.current) {
        btnRef.current.click();
      }
    }
  };

  const handleLogout = () => {
    dispatch(updateUserSession({session: null}));
  };

  return (
    <Container maxW="100%">
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: ['100%'],
          py: [3],
        }}
      >
        <Flex sx={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <IconButton
            size="sm"
            fontSize="lg"
            variant="ghost"
            mx={[1]}
            onClick={() => {
              if (showToggleSidebar) {
                toggleSidebarSize();
              } else {
                onOpenMenu();
              }
            }}
            icon={<FiMenu size="24px" />}
            aria-label={`open menu`}
            sx={{display: ['none', 'none', 'flex']}}
          />
          <Drawer placement="left" onClose={onCloseMenu} isOpen={isOpenMenu}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">
                <Box onClick={onCloseMenu}>{isOpenMenu ? <FiX size="24px" /> : <FiMenu size="24px" />}</Box>
              </DrawerHeader>
              <DrawerBody sx={{p: 0}}>
                <Flex sx={{flexDirection: 'column'}}>
                  <NavItem navSize="large" icon={FiHome} title="Home" to="/" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={FiYoutube} title="Movies" to="/movies" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={FiUsers} title="Actors" to="/actors" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={BiCategory} title="Genres" to="/genres" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={GiDirectorChair} title="Makers" to="/makers" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={ImBarcode} title="Codes" to="/codes" onClick={onCloseMenu} />
                  <NavItem navSize="large" icon={FiStar} title="Pro" to="/pro" onClick={onCloseMenu} />
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          <LinkBox sx={{ml: [0, 0, 4]}}>
            <Link to={{pathname: '/'}}>
              <Flex sx={{flexDirection: 'row', alignItems: 'center'}}>
                <IconButton
                  size="sm"
                  fontSize="lg"
                  variant="ghost"
                  icon={<FiYoutube size="30px" fill="#61525a" />}
                  aria-label="logo"
                  mr={[1]}
                />
                <Heading size="md">JAV Warp</Heading>
              </Flex>
            </Link>
          </LinkBox>
        </Flex>

        <HStack sx={{display: ['none', 'flex']}}>
          <InputGroup>
            <Input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeypress}
              sx={{maxWidth: ['500px']}}
            />
            <InputRightAddon sx={{px: [0]}}>
              <IconButton
                ref={btnRef}
                variant="ghost"
                onClick={submitSearch}
                icon={<FiSearch />}
                aria-label={`submit search`}
              />
            </InputRightAddon>
          </InputGroup>
        </HStack>

        {/* for destop */}
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            display: ['none', 'flex'],
          }}
        >
          <ColorModeSwitcher />

          {session ? (
            <Menu>
              <MenuButton
                as={IconButton}
                size="sm"
                fontSize="lg"
                variant="ghost"
                color="current"
                marginLeft="2"
                icon={<FiUser />}
                aria-label={`Profile`}
              />
              <MenuList>
                <MenuGroup>
                  <MenuItem>My Account</MenuItem>
                  <MenuItem>Change Password</MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup>
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          ) : (
            <Link to={{pathname: '/login'}}>
              <Text sx={{ml: [2]}}>Login</Text>
            </Link>
          )}
        </Flex>

        {/* for mobile */}
        <HStack sx={{display: ['flex', 'none']}}>
          <IconButton
            size="sm"
            fontSize="lg"
            variant="ghost"
            marginLeft="2"
            onClick={onOpenSearch}
            icon={<FiSearch />}
            aria-label={`search movies`}
          />
          <Drawer placement="left" size="full" onClose={onCloseSearch} isOpen={isOpenSearch}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px" sx={{py: [3]}}>
                <HStack>
                  <IconButton
                    ref={btnRef}
                    size="sm"
                    fontSize="lg"
                    variant="ghost"
                    marginLeft="2"
                    onClick={onCloseSearch}
                    icon={<FiArrowLeft />}
                    aria-label={`close search`}
                  />
                  <Input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleKeypress}
                    size="sm"
                  />
                  <IconButton
                    ref={btnRef}
                    size="sm"
                    fontSize="lg"
                    variant="ghost"
                    marginLeft="2"
                    onClick={submitSearch}
                    icon={<FiSearch />}
                    aria-label={`submit search`}
                  />
                </HStack>
              </DrawerHeader>
            </DrawerContent>
          </Drawer>

          <ColorModeSwitcher />

          <IconButton
            size="sm"
            fontSize="lg"
            variant="ghost"
            marginLeft="2"
            onClick={onOpenSettings}
            icon={<FiMoreVertical />}
            aria-label={`open menu`}
          />
          <Drawer placement="right" onClose={onCloseSettings} isOpen={isOpenSettings}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">
                <Box onClick={onCloseSettings}>{isOpenSettings ? <FiX size="24px" /> : <FiMenu size="24px" />}</Box>
              </DrawerHeader>
              <DrawerBody>
                <Flex sx={{flexDirection: 'column'}}>
                  <Link to={{pathname: '/movies'}}>
                    <Button variant="menu" onClick={onCloseSettings}>
                      <Text variant="header">movies</Text>
                    </Button>
                  </Link>
                  <Link to={{pathname: '/pro'}}>
                    <Button variant="menu" onClick={onCloseSettings}>
                      <Text variant="header">pro</Text>
                    </Button>
                  </Link>
                  {session ? (
                    <>
                      <Link to={{pathname: '/settings'}}>
                        <Button variant="menu" onClick={onCloseSettings}>
                          <Text variant="header">setting</Text>
                        </Button>
                      </Link>
                      <Box>
                        <Button
                          variant="menu"
                          onClick={() => {
                            onCloseSettings();
                            handleLogout();
                          }}
                        >
                          <Text variant="header">logout</Text>
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Link to={{pathname: '/login'}} onClick={onCloseSettings}>
                      <Button variant="menu">
                        <Text variant="header">login</Text>
                      </Button>
                    </Link>
                  )}
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </HStack>
      </Flex>
    </Container>
  );
};
