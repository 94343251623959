import {useQuery} from '@apollo/client';
import {Box, Center} from '@chakra-ui/layout';
import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Switch,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import queryString from 'query-string';
import React, {ReactNode, useMemo, useState} from 'react';
import {FaSortNumericDown, FaSortNumericUp} from 'react-icons/fa';
import {FiFilter, FiRotateCw} from 'react-icons/fi';
import {MdSortByAlpha} from 'react-icons/md';
import {useHistory, useLocation} from 'react-router';
import {CARD_PREVIEW_MAX_WIDTH, MOVIE_ITEM_PER_PAGE} from '../../constant';
import {ListMoviesResult, ListMoviesVars, LIST_MOVIES, Movie, MovieOrderField, WhereMovie} from '../../graph/movie';
import {OrderDirection} from '../../graph/types';
import {useSessionManager} from '../../state/user/hooks';
import {Footer} from '../Footer/Footer';
import {Pagination} from '../Pagination/Pagination';
import {Sidebar} from '../Sidebar/Sidebar';
import {NotFound} from '../Utils/NotFound';
import {MovieCardPreivew} from './MovieCardPreview';

export const MovieList = ({where = {}, nodeId, hero}: {where?: WhereMovie; nodeId?: number; hero?: ReactNode}) => {
  const {session} = useSessionManager();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const page = parseInt(parsed.page as string) || 1;
  const itemPerPage = parseInt(parsed.itemPerPage as string) || MOVIE_ITEM_PER_PAGE;
  const query = (parsed.query as string) || undefined;
  const hasVideo = (parsed.hasVideo as string) === 'true' || undefined;
  const hasTorrent = (parsed.hasTorrent as string) === 'true' || undefined;
  const hasSubtitle = (parsed.hasSubtitle as string) === 'true' || undefined;
  const isSolo = (parsed.isSolo as string) === 'true' || undefined;
  const isPro = (parsed.isPro as string) === 'true' || undefined;
  const isDownloading = (parsed.isDownloading as string) === 'true' || undefined;

  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const pageCount = isSmallerThan768 ? 5 : 10;
  const [isHasVideoChecked, setIsHasVideoChecked] = useState(hasVideo || false);
  const [isHasTorrentChecked, setIsHasTorrentChecked] = useState(hasTorrent || false);
  const [isHasSubtitleChecked, setIsHasSubtitleChecked] = useState(hasSubtitle || false);
  const [isSoloChecked, setIsSoloChecked] = useState(isSolo || false);
  const [isProChecked, setIsProChecked] = useState(isPro || false);
  const [isDownloadingChecked, setIsDownloadingChecked] = useState(isDownloading || false);
  const [orderByField, setOrderByField] = useState<MovieOrderField>(MovieOrderField.RELEASED_AT);
  const [orderByDirection, setOrderByDirection] = useState<OrderDirection>(OrderDirection.DESC);

  const {data: result, refetch} = useQuery<ListMoviesResult, ListMoviesVars>(LIST_MOVIES, {
    variables: {
      orderBy: {field: orderByField, direction: orderByDirection},
      where: {query, page, itemPerPage, hasVideo, hasTorrent, hasSubtitle, isSolo, isPro, isDownloading, ...where},
    },
  });

  const items: Movie[] | undefined[] = useMemo(() => {
    if (!result) {
      return Array.from({length: itemPerPage}, () => undefined);
    }
    return result.movies.edges.map((edge) => edge.node);
  }, [result, itemPerPage]);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: isOpenSorting, onOpen: onOpenSorting, onClose: onCloseSorting} = useDisclosure();

  const onApplyFilter = () => {
    onClose();

    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        ...parsed,
        hasVideo: isHasVideoChecked,
        hasTorrent: isHasTorrentChecked,
        hasSubtitle: isHasSubtitleChecked,
        isSolo: isSoloChecked,
        isPro: isProChecked,
        isDownloading: isDownloadingChecked,
      }),
    });
  };

  const filterModal = (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={[4]}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="has-video" mb="0">
                Video?
              </FormLabel>
              <Switch
                id="has-video"
                isChecked={isHasVideoChecked}
                onChange={(e) => setIsHasVideoChecked(e.target.checked)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="has-subtitle" mb="0">
                Subtitle?
              </FormLabel>
              <Switch
                id="has-subtitle"
                isChecked={isHasSubtitleChecked}
                onChange={(e) => setIsHasSubtitleChecked(e.target.checked)}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="is-solo" mb="0">
                Solo?
              </FormLabel>
              <Switch id="is-solo" isChecked={isSoloChecked} onChange={(e) => setIsSoloChecked(e.target.checked)} />
            </FormControl>

            {session && session.user?.isAdmin && (
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="has-torrent" mb="0">
                  Torrent?
                </FormLabel>
                <Switch
                  id="has-torrent"
                  isChecked={isHasTorrentChecked}
                  onChange={(e) => setIsHasTorrentChecked(e.target.checked)}
                />
              </FormControl>
            )}

            {session && session.user?.isAdmin && (
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="is-downloading" mb="0">
                  Downloading?
                </FormLabel>
                <Switch
                  id="is-downloading"
                  isChecked={isDownloadingChecked}
                  onChange={(e) => setIsDownloadingChecked(e.target.checked)}
                />
              </FormControl>
            )}

            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel htmlFor="is-pro" mb="0">
                Pro?
              </FormLabel>
              <Switch id="is-pro" isChecked={isProChecked} onChange={(e) => setIsProChecked(e.target.checked)} />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="pink" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={onApplyFilter}>
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const sortingModal = (
    <Modal isOpen={isOpenSorting} onClose={onCloseSorting} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sort</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={[4]}>
            <HStack justifyContent="space-between" w={'full'}>
              <Text>Release Date</Text>
              {orderByField === MovieOrderField.RELEASED_AT && orderByDirection === OrderDirection.ASC ? (
                <IconButton
                  size="sm"
                  fontSize="lg"
                  variant="ghost"
                  onClick={() => {
                    setOrderByField(MovieOrderField.RELEASED_AT);
                    setOrderByDirection(OrderDirection.DESC);
                  }}
                  icon={<FaSortNumericDown />}
                  aria-label={`sort desc`}
                />
              ) : (
                <IconButton
                  size="sm"
                  fontSize="lg"
                  variant="ghost"
                  onClick={() => {
                    setOrderByField(MovieOrderField.RELEASED_AT);
                    setOrderByDirection(OrderDirection.ASC);
                  }}
                  icon={<FaSortNumericUp />}
                  aria-label={`sort asc`}
                />
              )}
            </HStack>

            {session && session.user?.isAdmin && (
              <HStack justifyContent="space-between" w={'full'}>
                <Text>View Count</Text>
                {orderByField === MovieOrderField.VIDEO_VIEW_COUNT && orderByDirection === OrderDirection.ASC ? (
                  <IconButton
                    size="sm"
                    fontSize="lg"
                    variant="ghost"
                    onClick={() => {
                      setOrderByField(MovieOrderField.VIDEO_VIEW_COUNT);
                      setOrderByDirection(OrderDirection.DESC);
                    }}
                    icon={<FaSortNumericDown />}
                    aria-label={`sort desc`}
                  />
                ) : (
                  <IconButton
                    size="sm"
                    fontSize="lg"
                    variant="ghost"
                    onClick={() => {
                      setOrderByField(MovieOrderField.VIDEO_VIEW_COUNT);
                      setOrderByDirection(OrderDirection.ASC);
                    }}
                    icon={<FaSortNumericUp />}
                    aria-label={`sort asc`}
                  />
                )}
              </HStack>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="pink" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={onApplyFilter}>
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Flex
      sx={{
        width: ['100%'],
        flexDirection: 'row',
      }}
    >
      <Sidebar />

      <Container maxW="100%">
        {hero && <Center sx={{px: [0, 0, 6], pt: [4]}}>{hero}</Center>}

        <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', overflowX: 'auto', px: [0, 0, 6], py: [3]}}>
          <HStack spacing={[4]}>
            <Tag colorScheme="pink">
              <TagLabel>All</TagLabel>
            </Tag>
            <Tag colorScheme="pink">
              <TagLabel>Same Actor</TagLabel>
            </Tag>
            <Tag colorScheme="pink">
              <TagLabel>Same Genre</TagLabel>
            </Tag>
          </HStack>

          <HStack>
            <Skeleton isLoaded={!!result} sx={{display: ['none', 'block']}}>
              <Text fontSize="sm">{result?.movies.totalCount.toLocaleString()} Items</Text>
            </Skeleton>
            <IconButton
              size="sm"
              fontSize="lg"
              variant="ghost"
              onClick={() => refetch()}
              icon={<FiRotateCw />}
              aria-label={`refetch`}
              // sx={{display: ['none', 'none', 'flex']}}
            />
            <IconButton
              size="sm"
              fontSize="lg"
              variant="ghost"
              onClick={onOpen}
              icon={<FiFilter />}
              aria-label={`open filter`}
              // sx={{display: ['none', 'none', 'flex']}}
            />
            <IconButton
              size="sm"
              fontSize="lg"
              variant="ghost"
              onClick={onOpenSorting}
              icon={<MdSortByAlpha />}
              aria-label={`open filter`}
              // sx={{display: ['none', 'none', 'flex']}}
            />
            {filterModal}
            {sortingModal}
          </HStack>
        </Flex>

        <Box sx={{px: [0, 0, 6], pb: [0]}}>
          <SimpleGrid minChildWidth={CARD_PREVIEW_MAX_WIDTH} spacing="4">
            {items.map((item, id) => (
              <MovieCardPreivew
                item={item}
                key={id}
                onLikeMovie={refetch}
                isAdmin={session?.user?.isAdmin}
                nodeId={nodeId}
              />
            ))}
          </SimpleGrid>
        </Box>

        <Box sx={{mb: [14, 0]}}>
          {result && page <= result.movies.pageInfo.totalPage && (
            <Pagination
              currentPage={page}
              pageInfo={result.movies.pageInfo}
              itemPerPage={itemPerPage}
              pageCount={pageCount}
            />
          )}
        </Box>

        {result && result.movies.totalCount === 0 && <NotFound />}

        <Footer />
      </Container>
    </Flex>
  );
};
