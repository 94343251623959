import {
  AspectRatio,
  AvatarGroup,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  LinkBox,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {FaThemeco} from 'react-icons/fa';
import {FiDownloadCloud, FiVideo} from 'react-icons/fi';
import {MdOutlineSubtitles} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {MOVIE_ASPECT_RATIO} from '../../constant';
import {Movie} from '../../graph/movie';
import {computeDate} from '../../utils';
import {LikeButton} from '../Button/LikeButton';
import {SearchTorrentButton} from '../Button/SearchTorrentButton';
import {SearchVideoButton} from '../Button/SearchVideoButton';
import {UpdateCoverImageButton} from '../Button/UpdateCoverImageButton';

export const MovieCardPreivew = ({
  item,
  onLikeMovie,
  isAdmin = false,
  nodeId,
}: {
  item?: Movie;
  onLikeMovie?: () => void;
  isAdmin?: boolean;
  nodeId?: number;
}) => {
  return (
    <LinkBox as="article" sx={{mb: [4]}} boxShadow="lg">
      <Skeleton width="100%" isLoaded={!!item}>
        <Link to={{pathname: `/movies/${item?.id}`}}>
          <AspectRatio ratio={MOVIE_ASPECT_RATIO} sx={{bg: 'black'}}>
            <Image src={item?.coverImage} alt={item?.cid} objectFit="cover" />
          </AspectRatio>
        </Link>
      </Skeleton>
      <Box sx={{px: [4], pt: [2], pb: [4]}}>
        <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <SkeletonText isLoaded={!!item} width="100%" noOfLines={1}>
            <HStack>
              <Link to={`/codes/${item?.code.id}`}>
                <Heading size="md" textStyle="movieCode">
                  {item?.cid}
                </Heading>
              </Link>
              {item?.hasVideo && <Icon as={FiVideo} />}
              {item?.hasSubtitle && <Icon as={MdOutlineSubtitles} />}
              {item?.hasTorrent && <Icon as={FiDownloadCloud} />}
              {item?.isPro && <Icon as={FaThemeco} />}
            </HStack>
          </SkeletonText>
          <Flex>
            {isAdmin && item && nodeId && <UpdateCoverImageButton movieId={item?.id} id={nodeId} />}
            {isAdmin && item && <SearchVideoButton movieId={item?.id} />}
            {isAdmin && item && <SearchTorrentButton movieId={item?.id} />}
            {item && <LikeButton movieId={item.id} isLiked={item.isLiked} onCompleted={onLikeMovie} />}
          </Flex>
        </Flex>

        <SkeletonText isLoaded={!!item} width="100%" noOfLines={1}>
          <Flex sx={{flexDirection: 'row'}} textStyle="movieMeta">
            <Link to={`/makers/${item?.maker.id}`}>
              <Text isTruncated>{item?.maker.name}</Text>
            </Link>
            <Text sx={{px: [1]}}>•</Text>
            <Text as="time" dateTime={item?.releasedAt}>
              {computeDate(item?.releasedAt)}
            </Text>
            {isAdmin && (
              <>
                <Text sx={{px: [1]}}>•</Text>
                <Text sx={{px: [1]}}>{item?.videoViewCount} views</Text>
                <Text sx={{px: [1]}}>•</Text>
                <Text>{item?.source}</Text>
              </>
            )}
          </Flex>
        </SkeletonText>

        <SkeletonText isLoaded={!!item} width="100%" noOfLines={1}>
          <Text isTruncated sx={{py: [2]}}>
            {item?.title}
          </Text>
        </SkeletonText>

        <AvatarGroup size="md" max={5}>
          {item?.actors.map((item) => (
            <LinkBox key={item.id}>
              <Link to={{pathname: `/actors/${item.id}`}}>
                <AspectRatio ratio={1} boxSize={['50px']}>
                  <Image
                    borderRadius="full"
                    src={item.coverImage}
                    alt={item.name}
                    objectFit="cover"
                    objectPosition="100% 0%"
                  />
                </AspectRatio>
              </Link>
            </LinkBox>
          ))}
        </AvatarGroup>
      </Box>
    </LinkBox>
  );
};
