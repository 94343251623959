import {useQuery} from '@apollo/client';
import {Flex} from '@chakra-ui/layout';
import React from 'react';
import {useParams} from 'react-router';
import {CodeCardPreivew} from '../../components/Code/CodeCardPreview';
import {MovieList} from '../../components/Movie/MovieList';
import {GetCodeResult, GetCodeVars, GET_CODE} from '../../graph/code';

export default function CodeId() {
  const {id} = useParams<{id: string}>();
  const codeId = parseInt(id);

  const {data: result} = useQuery<GetCodeResult, GetCodeVars>(GET_CODE, {
    variables: {
      id: codeId,
    },
  });

  const hero = (
    <Flex>
      <CodeCardPreivew item={result?.code} />
    </Flex>
  );

  return <MovieList where={{codeId}} hero={hero} nodeId={codeId} />;
}
