import gql from 'graphql-tag';
import {Node} from './types';

export type Torrent = Node & {
  name: string;
  status: string;
  length: number;
  size: string;
  date: string;
};

export const torrentFragment = gql`
  fragment torrentFields on Torrent {
    id
    name
    status
    length
    size
    date
  }
`;

export type DownloadTorrentResult = {
  torrent: Torrent;
};

export type DownladTorrentVars = {
  id: number;
};

export const DOWNLOAD_TORRENT = gql`
  mutation downloadTorrent($id: ID!) {
    torrent: downloadTorrent(id: $id) {
      ...torrentFields
    }
  }
  ${torrentFragment}
`;

export type CancelTorrentResult = {
  torrent: Torrent;
};

export type CancelTorrentVars = {
  id: number;
};

export const CANCEL_TORRENT = gql`
  mutation cancelTorrent($id: ID!) {
    torrent: cancelTorrent(id: $id) {
      ...torrentFields
    }
  }
  ${torrentFragment}
`;

export type RetryTorrentResult = {
  torrent: Torrent;
};

export type RetryTorrentVars = {
  id: number;
};

export const RETRY_TORRENT = gql`
  mutation retryTorrent($id: ID!) {
    torrent: retryTorrent(id: $id) {
      ...torrentFields
    }
  }
  ${torrentFragment}
`;

export type CleanupTorrentResult = {
  torrent: Torrent;
};

export type CleanupTorrentVars = {
  id: number;
};

export const CLEANUP_TORRENT = gql`
  mutation cleanupTorrent($id: ID!) {
    torrent: cleanupTorrent(id: $id) {
      ...torrentFields
    }
  }
  ${torrentFragment}
`;

export type DeleteTorrentResult = {
  id: number;
};

export type DeleteTorrentVars = {
  id: number;
};

export const DELETE_TORRENT = gql`
  mutation deleteTorrent($id: ID!) {
    id: deleteTorrent(id: $id)
  }
`;

export type AddMovieToGroupResult = {
  torrents: Torrent[];
};

export type AddMovieToGroupVars = {
  movieId: number;
  groupName: string;
};

export const ADD_MOVIE_TO_GROUP = gql`
  mutation addMovieToGroup($movieId: ID!, $groupName: String!) {
    group: addMovieToGroup(movieId: $movieId, groupName: $groupName) {
      id
    }
  }
`;

export type SyncTransferringTorrentResult = {
  torrents: Torrent[];
};

export type SyncTransferringTorrentVars = {
  id?: number;
};

export const SYNC_TRANSFERRING_TORRENT = gql`
  mutation syncTransferringTorrent {
    torrents: syncTransferringTorrent {
      ...torrentFields
    }
  }
  ${torrentFragment}
`;
