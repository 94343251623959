import {useMutation} from '@apollo/client';
import {IconButton, useToast} from '@chakra-ui/react';
import React from 'react';
import {FiImage} from 'react-icons/fi';
import {UpdateCoverImageResult, UpdateCoverImageVars, UPDATE_COVER_IMAGE} from '../../graph/movie';

export const UpdateCoverImageButton = ({
  movieId,
  id,
  onCompleted,
}: {
  movieId: number;
  id: number;
  onCompleted?: () => void;
}) => {
  const toast = useToast();
  const [updateCoverImage, {loading}] = useMutation<UpdateCoverImageResult, UpdateCoverImageVars>(UPDATE_COVER_IMAGE, {
    variables: {movieId, id},
    onCompleted: () => {
      toast({title: 'Successfully updated cover image'});
      if (onCompleted) onCompleted();
    },
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
  });

  return (
    <IconButton
      icon={<FiImage />}
      variant="ghost"
      aria-label="search"
      isLoading={loading}
      onClick={() => updateCoverImage()}
    />
  );
};
