import {addMonths, format, formatDistanceToNow, isBefore, parseISO} from 'date-fns';
import {User} from '../graph/auth';

export const getStringOrUndefined = (arg: string | string[] | null): string | undefined => {
  if (typeof arg === 'string') {
    return arg;
  } else if (Array.isArray(arg) && arg.length > 0) {
    return arg[0];
  }
  return undefined;
};

export const getIntOrUndefined = (arg: string | string[] | null): number | undefined => {
  if (typeof arg === 'string') {
    return parseInt(arg);
  } else if (Array.isArray(arg) && arg.length > 0) {
    return parseInt(arg[0]);
  }
  return undefined;
};

export const computeDate = (releasedAt?: string, numMonth?: number): string => {
  if (!releasedAt) return '';

  const comparedDate = addMonths(new Date(), numMonth || -1);
  const releaseDate = parseISO(releasedAt);
  return isBefore(comparedDate, releaseDate) ? formatDistanceToNow(releaseDate) : format(releaseDate, 'dd/MM/yyyy');
};

export const isPro = (user: User | undefined) => {
  if (!user) return false;

  return user.putioAccessToken != '';
};
