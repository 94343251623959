import {AspectRatio, Box, Heading, Image, LinkBox, Skeleton, VStack} from '@chakra-ui/react';
import React from 'react';
import {Link} from 'react-router-dom';
import {MOVIE_ASPECT_RATIO_HALF} from '../../constant';
import {Actor} from '../../graph/actor';

export const ActorCardPreivew = ({item}: {item?: Actor}) => {
  return (
    <LinkBox as="article" sx={{mb: [4]}} boxShadow="lg">
      <Skeleton isLoaded={!!item}>
        <Link to={{pathname: `/actors/${item?.id}`}}>
          <AspectRatio ratio={MOVIE_ASPECT_RATIO_HALF} sx={{bg: 'black'}}>
            <Image
              src={item?.coverImage}
              alt={item?.name}
              sx={{
                objectFit: 'cover',
                objectPosition: '100% 0%',
              }}
            />
          </AspectRatio>
        </Link>
      </Skeleton>

      <Box sx={{px: [4], pb: [4]}}>
        <VStack sx={{mt: [2]}}>
          <Skeleton isLoaded={!!item} width="100%" mt={[2]}>
            <Heading size="md" textStyle="movieCode" textTransform="capitalize">
              {item?.name}
            </Heading>
          </Skeleton>
        </VStack>
      </Box>
    </LinkBox>
  );
};
