import {gql} from '@apollo/client';
import {Connection, Node, OrderDirection} from './types';

export type Maker = Node & {
  name: string;
  coverImage: string;
};

export enum MakerOrderField {
  LATEST_RELEASED_AT = 'LATEST_RELEASED_AT',
  NAME = 'NAME',
}

export type MakerOrder = {
  field: MakerOrderField;
  direction: OrderDirection;
};

export type WhereMaker = {
  query?: string;
  page?: number;
  itemPerPage?: number;
};

export const makerFragment = gql`
  fragment makerFields on Maker {
    id
    name
    coverImage
  }
`;

export const makerOffsetConnectionFragment = gql`
  ${makerFragment}

  fragment makerOffsetConnectionFields on MakerOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...makerFields
      }
    }
  }
`;

export type ListMakersResult = {
  makers: Connection<Maker>;
};

export type ListMakersVars = {
  orderBy?: MakerOrder;
  where?: WhereMaker;
};

export const LIST_MAKERS = gql`
  query makers($orderBy: MakerOrder, $where: WhereMaker) {
    makers: makers(orderBy: $orderBy, where: $where) {
      ...makerOffsetConnectionFields
    }
  }
  ${makerOffsetConnectionFragment}
`;

export type GetMakerResult = {
  maker: Maker;
};

export type GetMakerVars = {
  id: number;
};

export const GET_MAKER = gql`
  query maker($id: ID!) {
    maker: maker(id: $id) {
      ...makerFields
    }
  }
  ${makerFragment}
`;
