import {fromUnixTime, isBefore} from 'date-fns';
import {useCallback} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Session} from '../../graph/auth';
import {AppState} from '../index';

export const useSession = (): Session | null => {
  const session = useSelector<AppState, Session | null>(({user: {userSession}}) => userSession, shallowEqual);

  if (!session) return null;

  const expiresAt = fromUnixTime(parseInt(session.expiresAt) || 0);
  if (isBefore(expiresAt, new Date())) {
    return null;
  }

  return session;
};

export const useSessionManager = (): {session: Session | null; login: () => void; signup: () => void} => {
  const session = useSession();
  const history = useHistory();
  const location = useLocation();

  const login = useCallback(() => {
    history.push(`/login?callback=${location.pathname}`);
  }, [history, location]);

  const signup = useCallback(() => {
    history.push(`/signup?callback=${location.pathname}`);
  }, [history, location]);

  return {session, login, signup};
};
