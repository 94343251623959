import {extendTheme, ThemeConfig} from '@chakra-ui/react';
import type {Dict} from '@chakra-ui/utils';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
};

const colors: Dict = {};

const textStyles: Dict = {
  movieCode: {},
  movieMeta: {
    color: 'gray.400',
    fontSize: 'sm',
  },
  movieTitle: {},
};

const theme = extendTheme({config, colors, textStyles});

export default theme;
