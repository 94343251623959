import {createReducer} from '@reduxjs/toolkit';
import {Session} from '../../graph/auth';
import {updateVersion} from '../global/actions';
import {updateUserSession} from './actions';

const currentTimestamp = () => new Date().getTime();

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  userSession: Session | null;

  timestamp: number;
}

export const initialState: UserState = {
  userSession: null,
  timestamp: currentTimestamp(),
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      state.lastUpdateVersionTimestamp = currentTimestamp();
    })
    .addCase(updateUserSession, (state, action) => {
      state.userSession = action.payload.session;
      state.timestamp = currentTimestamp();
    })
);
