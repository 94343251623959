import {useQuery} from '@apollo/client';
import {Flex} from '@chakra-ui/layout';
import React from 'react';
import {useParams} from 'react-router';
import {GenreCardPreivew} from '../../components/Genre/GenreCardPreview';
import {MovieList} from '../../components/Movie/MovieList';
import {GetGenreResult, GetGenreVars, GET_GENRE} from '../../graph/genre';

export default function GenreId() {
  const {id} = useParams<{id: string}>();
  const genreId = parseInt(id);

  const {data: result} = useQuery<GetGenreResult, GetGenreVars>(GET_GENRE, {
    variables: {
      id: genreId,
    },
  });

  const hero = (
    <Flex>
      <GenreCardPreivew item={result?.genre} />
    </Flex>
  );

  return <MovieList where={{genreId}} hero={hero} nodeId={genreId} />;
}
