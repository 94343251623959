import {createReducer} from '@reduxjs/toolkit';
import {ListMoviesResult} from '../../graph/movie';
import {addListMoviesQueryResult} from './actions';

export interface MovieState {
  [page: string]: ListMoviesResult;
}

export const initialState: MovieState = {};

export default createReducer(initialState, (builder) =>
  builder.addCase(addListMoviesQueryResult, (state, action) => {
    const {
      result: {
        movies: {edges, pageInfo, totalCount},
      },
    } = action.payload;
    const page = state[action.payload.page] ?? {};

    state[action.payload.page] = {
      movies: {edges: [...(page.movies?.edges ?? []), ...edges], pageInfo, totalCount},
    };
  })
);
