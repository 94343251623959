import {Flex, Icon, LinkBox, Text} from '@chakra-ui/react';
import React from 'react';
import {Link} from 'react-router-dom';

export const NavItem = ({
  icon,
  title,
  to,
  navSize,
  onClick,
}: {
  icon: any;
  title: string;
  to: string;
  description?: string;
  active?: boolean;
  navSize: string;
  onClick?: () => void;
}) => {
  return (
    <LinkBox sx={{width: '100%'}} _hover={{bg: 'whiteAlpha.300'}} onClick={() => onClick && onClick()}>
      <Link to={to}>
        <Flex sx={{direction: 'row', py: [4]}}>
          <Icon as={icon} w={[6]} h={[6]} sx={{ml: [6]}} />
          <Text ml={[6]} fontSize="md" display={navSize == 'small' ? 'none' : 'flex'}>
            {title}
          </Text>
        </Flex>
      </Link>
    </LinkBox>
  );
};
