import {Box, Flex} from '@chakra-ui/react';
import {Location} from 'history';
import React, {ReactNode, Suspense} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router';
import {Navbar} from './components/Navbar/Navbar';
import Actors from './pages/Actors';
import ActorId from './pages/Actors/id';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import Codes from './pages/Codes';
import CodeId from './pages/Codes/id';
import Genres from './pages/Genres';
import GenreId from './pages/Genres/id';
import Home from './pages/Home';
import Like from './pages/Like';
import Makers from './pages/Makers';
import MakerId from './pages/Makers/id';
import Movies from './pages/Movies';
import MovieId from './pages/Movies/id';
import NotFound from './pages/NotFound';
import Pro from './pages/Pro';
import Settings from './pages/Settings';
import Torrents from './pages/Torrents';
import {useSessionManager} from './state/user/hooks';

const AppWrapper = (props: {children?: ReactNode}): JSX.Element => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
    {...props}
  />
);

const NavbarWrapper = (props: {children?: ReactNode}): JSX.Element => (
  <Box
    id="navbarwrapper"
    sx={{
      top: 0,
      position: 'sticky',
      width: ['100%'],
      bg: 'pink.800',
      zIndex: 'overlay',
    }}
    {...props}
  />
);

const BodyWapper = (props: {children?: ReactNode}): JSX.Element => {
  return (
    <Flex
      id="bodywrapper"
      sx={{
        width: ['100%'],
        // height: '100vh',
        flexDirection: 'column',
      }}
      {...props}
    />
  );
};

const PrivateRoute = ({children, props}: {children: ReactNode; props?: any}) => {
  const {session} = useSessionManager();
  return (
    <Route
      {...props}
      render={({location}) =>
        session?.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};

export interface LocationState {
  background: Location<LocationState>;
}

export const App = () => {
  const location = useLocation<LocationState>();
  const background = location.state && location.state.background;

  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <NavbarWrapper>
          <Navbar />
        </NavbarWrapper>

        <BodyWapper>
          <Switch location={background || location}>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/movies" component={Movies} />
            <Route exact strict path="/movies/:id" component={MovieId} />
            <Route exact strict path="/actors" component={Actors} />
            <Route exact strict path="/actors/:id" component={ActorId} />
            <Route exact strict path="/genres" component={Genres} />
            <Route exact strict path="/genres/:id" component={GenreId} />
            <Route exact strict path="/makers" component={Makers} />
            <Route exact strict path="/makers/:id" component={MakerId} />
            <Route exact strict path="/codes" component={Codes} />
            <Route exact strict path="/codes/:id" component={CodeId} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/signup" component={Signup} />
            <PrivateRoute>
              <Route exact strict path="/pro" component={Pro} />
              <Route exact strict path="/like" component={Like} />
              <Route exact strict path="/torrents" component={Torrents} />
              <Route exact strict path="/settings" component={Settings} />
            </PrivateRoute>
            <Route path="*" component={NotFound} />
          </Switch>

          {/* {background && <Route exact strict path="/bers/:id" component={BerModal} />}
          {background && <Route exact strict path="/evaluate/:ber" component={EvaluationModal} />} */}
        </BodyWapper>
      </AppWrapper>
    </Suspense>
  );
};
