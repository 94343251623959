import {gql} from '@apollo/client';
import {Connection, Node, OrderDirection} from './types';

export type Actor = Node & {
  name: string;
  coverImage: string;
  movieCount: number;
  soloMovieCount: number;
};

export enum ActorOrderField {
  LATEST_RELEASED_AT = 'LATEST_RELEASED_AT',
  NAME = 'NAME',
  MOVIE_COUNT = 'MOVIE_COUNT',
  LATEST_MOVIE_COUNT = 'LATEST_MOVIE_COUNT',
}

export type ActorOrder = {
  field: ActorOrderField;
  direction: OrderDirection;
};

export type WhereActor = {
  query?: string;
  page?: number;
  itemPerPage?: number;
};

export const actorFragment = gql`
  fragment actorFields on Actor {
    id
    name
    coverImage
    movieCount
    soloMovieCount
  }
`;

export const actorOffsetConnectionFragment = gql`
  ${actorFragment}

  fragment actorOffsetConnectionFields on ActorOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...actorFields
      }
    }
  }
`;

export type ListActorsResult = {
  actors: Connection<Actor>;
};

export type ListActorsVars = {
  orderBy?: ActorOrder;
  where?: WhereActor;
};

export const LIST_ACTORS = gql`
  query actors($orderBy: ActorOrder, $where: WhereActor) {
    actors: actors(orderBy: $orderBy, where: $where) {
      ...actorOffsetConnectionFields
    }
  }
  ${actorOffsetConnectionFragment}
`;

export type GetActorResult = {
  actor: Actor;
};

export type GetActorVars = {
  id: number;
};

export const GET_ACTOR = gql`
  query actor($id: ID!) {
    actor: actor(id: $id) {
      ...actorFields
    }
  }
  ${actorFragment}
`;
