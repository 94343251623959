import {ApolloProvider} from '@apollo/client';
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {App} from './App';
import {client} from './connectors/graphql';
import {ScrollToTop} from './hooks/scrollToTop';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './state';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <ColorModeScript />
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
