import {useMutation} from '@apollo/client';
import {Box, Skeleton} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
import videojs, {VideoJsPlayerOptions} from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-hotkeys';
import {ViewVideoResult, ViewVideoVars, VIEW_VIDEO} from '../../graph/movie';
import {Video} from '../../graph/types';
import {useQueryPutio} from '../../hooks/useQueryPutio';

export const VideoJS = ({video}: {video: Video}) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<videojs.Player>();
  const {sources, tracks, loading} = useQueryPutio({fileId: video.uid, cid: video.cid});

  const [viewVideo] = useMutation<ViewVideoResult, ViewVideoVars>(VIEW_VIDEO, {
    variables: {id: video.id},
    ignoreResults: true,
  });

  // This seperate functional component fixes the removal of the videoelement
  // from the DOM when calling the dispose() method on a player
  const VideoHtml = () =>
    loading ? (
      <Skeleton height="100%" width="100%" />
    ) : (
      <Box data-vjs-player>
        <video ref={videoNode} className="video-js vjs-fluid" />
      </Box>
    );

  useEffect(() => {
    if (!videoNode.current || !sources || loading) return;

    const options: VideoJsPlayerOptions = {
      autoplay: false,
      controls: true,
      sources,
    };

    const player = videojs(videoNode.current, options, () => {
      if (tracks) {
        for (const track of tracks) {
          player.addRemoteTextTrack(track, false);
        }
      }

      player.bigPlayButton.on('click', () => {
        viewVideo().catch((err) => console.error(err));
      });
    });

    player.hotkeys({
      volumeStep: 0.1,
      seekStep: 3,
      enableModifiersForNumbers: false,
      fullscreenKey(event, _player) {
        // override fullscreen to trigger when pressing the F key or Ctrl+Enter
        return event.which === 70 || (event.ctrlKey && event.which === 13);
      },
      customKeys: {
        // Create custom hotkeys
        ctrldKey: {
          key(event) {
            // Toggle something with CTRL + D Key
            return event.ctrlKey && event.which === 68;
          },
          handler(player, _options, _event) {
            // Using mute as an example
            const offset = 0.5;
            if (player) {
              Array.from(player.textTracks()).forEach((track) => {
                if (track.mode === 'showing') {
                  //@ts-ignore
                  Array.from(track.cues).forEach((cue) => {
                    cue.startTime += offset;
                    cue.endTime += offset;
                  });
                  return true;
                }
              });
            }
          },
        },
        ctrlsKey: {
          key(event) {
            // Toggle something with CTRL + S Key
            return event.ctrlKey && event.which === 83;
          },
          handler(player, _options, _event) {
            const offset = 0.5;
            if (player) {
              Array.from(player.textTracks()).forEach((track) => {
                if (track.mode === 'showing') {
                  //@ts-ignore
                  Array.from(track.cues).forEach((cue) => {
                    cue.startTime -= offset;
                    cue.endTime -= offset;
                  });
                  return true;
                }
              });
            }
          },
        },
      },
    });

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [player, sources, tracks, loading, viewVideo]);

  return <VideoHtml />;
};
