import {useQuery} from '@apollo/client';
import {Box} from '@chakra-ui/layout';
import {
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Tag,
  TagLabel,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import queryString from 'query-string';
import React, {useMemo} from 'react';
import {FiFilter} from 'react-icons/fi';
import {useHistory, useLocation} from 'react-router';
import {CARD_PREVIEW_MAX_WIDTH_HALF, MAKER_ITEM_PER_PAGE} from '../../constant';
import {ListMakersResult, ListMakersVars, LIST_MAKERS, Maker, MakerOrderField} from '../../graph/maker';
import {OrderDirection} from '../../graph/types';
import {Footer} from '../Footer/Footer';
import {Pagination} from '../Pagination/Pagination';
import {Sidebar} from '../Sidebar/Sidebar';
import {NotFound} from '../Utils/NotFound';
import {MakerCardPreivew} from './MakerCardPreview';

export const MakerList = () => {
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const page = parseInt(parsed.page as string) || 1;
  const itemPerPage = parseInt(parsed.itemPerPage as string) || MAKER_ITEM_PER_PAGE;
  const query = (parsed.query as string) || undefined;

  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const pageCount = isSmallerThan768 ? 5 : 10;

  const {data: result} = useQuery<ListMakersResult, ListMakersVars>(LIST_MAKERS, {
    variables: {
      orderBy: {field: MakerOrderField.LATEST_RELEASED_AT, direction: OrderDirection.DESC},
      where: {page, itemPerPage, query},
    },
  });

  const items: Maker[] | undefined[] = useMemo(() => {
    if (!result) {
      return Array.from({length: itemPerPage}, () => undefined);
    }
    return result.makers.edges.map((edge) => edge.node);
  }, [result, itemPerPage]);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const onApplyFilter = () => {
    onClose();

    history.push({
      pathname: location.pathname,
      search: queryString.stringify({...parsed}),
    });
  };

  const filterModal = (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={[4]}></VStack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="pink" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={onApplyFilter}>
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Flex
      sx={{
        width: ['100%'],
        flexDirection: 'row',
      }}
    >
      <Sidebar />

      <Container maxW="100%">
        <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', overflowX: 'auto', px: [0, 0, 6], py: [3]}}>
          <HStack spacing={[4]}>
            <Tag colorScheme="pink">
              <TagLabel>All</TagLabel>
            </Tag>
            <Tag colorScheme="pink">
              <TagLabel>Same Maker</TagLabel>
            </Tag>
            <Tag colorScheme="pink">
              <TagLabel>Same Maker</TagLabel>
            </Tag>
          </HStack>
          <IconButton
            size="sm"
            fontSize="lg"
            variant="ghost"
            onClick={onOpen}
            icon={<FiFilter />}
            aria-label={`open filter`}
            // sx={{display: ['none', 'none', 'flex']}}
          />
          {filterModal}
        </Flex>

        <Box sx={{px: [0, 0, 6], pb: [0]}}>
          <SimpleGrid minChildWidth={CARD_PREVIEW_MAX_WIDTH_HALF} spacing="4">
            {items.map((item, id) => (
              <MakerCardPreivew item={item} key={id} />
            ))}
          </SimpleGrid>
        </Box>

        <Box sx={{mb: [14, 0]}}>
          {result && page <= result.makers.pageInfo.totalPage && (
            <Pagination
              currentPage={page}
              pageInfo={result.makers.pageInfo}
              itemPerPage={itemPerPage}
              pageCount={pageCount}
            />
          )}
        </Box>

        {result && result.makers.totalCount === 0 && <NotFound />}

        <Footer />
      </Container>
    </Flex>
  );
};
