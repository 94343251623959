import {gql} from '@apollo/client';
import {Connection, Node, OrderDirection} from './types';

export type Code = Node & {
  name: string;
  coverImage: string;
};

export enum CodeOrderField {
  LATEST_RELEASED_AT = 'LATEST_RELEASED_AT',
  NAME = 'NAME',
}

export type CodeOrder = {
  field: CodeOrderField;
  direction: OrderDirection;
};

export type WhereCode = {
  query?: string;
  page?: number;
  itemPerPage?: number;
};

export const codeFragment = gql`
  fragment codeFields on Code {
    id
    name
    coverImage
  }
`;

export const codeOffsetConnectionFragment = gql`
  ${codeFragment}

  fragment codeOffsetConnectionFields on CodeOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...codeFields
      }
    }
  }
`;

export type ListCodesResult = {
  codes: Connection<Code>;
};

export type ListCodesVars = {
  orderBy?: CodeOrder;
  where?: WhereCode;
};

export const LIST_CODES = gql`
  query codes($orderBy: CodeOrder, $where: WhereCode) {
    codes: codes(orderBy: $orderBy, where: $where) {
      ...codeOffsetConnectionFields
    }
  }
  ${codeOffsetConnectionFragment}
`;

export type GetCodeResult = {
  code: Code;
};

export type GetCodeVars = {
  id: number;
};

export const GET_CODE = gql`
  query code($id: ID!) {
    code: code(id: $id) {
      ...codeFields
    }
  }
  ${codeFragment}
`;
