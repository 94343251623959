import {gql} from '@apollo/client';

export type User = {
  email: string;
  putioAccessToken: string;
  putioClientID: string;
  isAdmin: boolean;
};

export type Session = {
  user?: User;
  accessToken: string;
  expiresAt: string;
  tokenType: string;
};

export type LoginUsernameResult = {
  session: Session;
};

export type LoginUsernameVars = {
  username: string;
  password: string;
};

export const LOGIN_USERNAME = gql`
  mutation loginUsername($username: String!, $password: String!) {
    session: loginUsername(input: {username: $username, password: $password}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        putioAccessToken
        putioClientID
        isAdmin
      }
    }
  }
`;

export type SignupUsernameResult = {
  session: Session;
};

export type SignupUsernameVars = {
  username: string;
  password: string;
  email: string;
};

export const SIGNUP_USERNAME = gql`
  mutation loginUsername($username: String!, $password: String!, $username: String!) {
    session: signupUsername(input: {username: $username, password: $password, username: $username}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        putioAccessToken
        putioClientID
        isAdmin
      }
    }
  }
`;
