import {useQuery} from '@apollo/client';
import {
  AspectRatio,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {useMemo} from 'react';
import {FaThemeco} from 'react-icons/fa';
import {FiDownloadCloud, FiRotateCw, FiVideo} from 'react-icons/fi';
import {MdOutlineSubtitles} from 'react-icons/md';
import {Link} from 'react-router-dom';
import MovieSuggestion from '../../components/Movie/MovieSuggestion';
import {TorrentController} from '../../components/Torrents/TorrentController';
import {VideoPlayer} from '../../components/Video/Video';
import {MOVIE_ASPECT_RATIO} from '../../constant';
import {GetMovieResult, GetMovieVars, GET_MOVIE} from '../../graph/movie';
import {useSession} from '../../state/user/hooks';
import {computeDate} from '../../utils';
import {AddSubtitleButton} from '../Button/AddSubtitleButton';
import {LikeButton} from '../Button/LikeButton';
import {SearchTorrentButton} from '../Button/SearchTorrentButton';
import {SearchVideoButton} from '../Button/SearchVideoButton';
import {UpdateCoverImageButton} from '../Button/UpdateCoverImageButton';

export const MovieItem = ({movieId}: {movieId: number}) => {
  const session = useSession();

  const {data: result, refetch} = useQuery<GetMovieResult, GetMovieVars>(GET_MOVIE, {
    variables: {id: movieId},
  });

  const bgColor = useColorModeValue('white', 'gray.800');

  const sortedVideo = useMemo(() => {
    if (!result) return [];
    const videoOrder = ['PUT'];
    result?.movie.videos.map;

    const videos = [...result.movie.videos];

    videos.sort((a, b) => {
      return videoOrder.indexOf(b.source) - videoOrder.indexOf(a.source);
    });

    return videos;
  }, [result]);

  return (
    <Container maxW={['108em']} sx={{px: [0, 0, 6]}}>
      <Grid templateRows={['repeat(1,1fr)']} templateColumns={['repeat(2,1fr)', null, '70% 30%']} gridGap={[0]}>
        <GridItem colSpan={[2, 2, 2, 1]} sx={{py: [null, null, 6]}}>
          <Box
            sx={{
              // top: [8],
              position: ['sticky', null, 'relative'],
              width: ['100%'],
              zIndex: ['sticky', null, 'auto'],
              bg: bgColor,
            }}
          >
            {sortedVideo.length > 0 ? (
              <Tabs>
                <TabPanels>
                  {sortedVideo.map((item, id) => {
                    return (
                      <TabPanel key={`tab-panels-${id}`} sx={{p: 0}}>
                        <AspectRatio ratio={MOVIE_ASPECT_RATIO}>
                          <Box sx={{width: '100%', height: '300px'}}>
                            <VideoPlayer video={item} />
                          </Box>
                        </AspectRatio>
                      </TabPanel>
                    );
                  })}
                </TabPanels>
                <TabList>
                  {sortedVideo.map((_item, id) => {
                    return <Tab key={`tab-list-${id}`}>{id + 1}</Tab>;
                  })}
                </TabList>
              </Tabs>
            ) : (
              <AspectRatio ratio={MOVIE_ASPECT_RATIO}>
                <Image
                  src={result?.movie.coverImage}
                  alt={result?.movie.cid}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
              </AspectRatio>
            )}
          </Box>

          <Box>
            <Box sx={{px: [4, 0]}}>
              <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: [2]}}>
                <SkeletonText isLoaded={!!result} width="100%" noOfLines={1}>
                  <HStack>
                    <Link to={`/codes/${result?.movie.code.id}`}>
                      <Heading size="md" textStyle="movieCode">
                        {result?.movie.cid}
                      </Heading>
                    </Link>
                    {result?.movie.hasVideo && <Icon as={FiVideo} />}
                    {result?.movie.hasSubtitle && <Icon as={MdOutlineSubtitles} />}
                    {result?.movie.hasTorrent && <Icon as={FiDownloadCloud} />}
                    {result?.movie.isPro && <Icon as={FaThemeco} />}
                  </HStack>
                </SkeletonText>
                <Flex>
                  <IconButton variant="ghost" onClick={() => refetch()} icon={<FiRotateCw />} aria-label={`refetch`} />
                  {session?.user?.isAdmin && result && (
                    <SearchVideoButton movieId={result.movie.id} onCompleted={refetch} />
                  )}
                  {session?.user?.isAdmin && result && <SearchTorrentButton movieId={result.movie.id} />}
                  {result && (
                    <LikeButton movieId={result.movie.id} isLiked={result.movie.isLiked} onCompleted={refetch} />
                  )}
                </Flex>
              </Flex>
              <Flex textStyle="movieMeta" sx={{flexDirection: 'row'}}>
                <Link to={`/makers/${result?.movie.maker.id}`}>
                  <Text>{result?.movie.maker.name}</Text>
                </Link>
                <Text sx={{px: [1]}}>•</Text>
                <Text>{computeDate(result?.movie.releasedAt)}</Text>

                {session?.user?.isAdmin && (
                  <>
                    <Text sx={{px: [1]}}>•</Text>
                    <Text sx={{px: [1]}}>{result?.movie.videoViewCount} views</Text>
                  </>
                )}
              </Flex>
              <Flex sx={{py: [4], maxW: 'md'}}>
                <Text>{result?.movie.title}</Text>
              </Flex>
            </Box>

            <Divider sx={{my: [2]}} />

            <HStack spacing={[4]} sx={{px: [4, 0], py: [4], overflowX: 'auto'}}>
              {result?.movie.genres.map((item, id) => {
                return (
                  <Link to={`/genres/${item.id}`} key={`genres-${id}`}>
                    <Tag colorScheme="pink">
                      <TagLabel>{item.name}</TagLabel>
                    </Tag>
                  </Link>
                );
              })}
            </HStack>

            <Divider sx={{my: [2]}} />

            {result?.movie.videos && result.movie.videos.length > 0 && (
              <>
                <Box sx={{maxW: 'md'}}>
                  <AspectRatio ratio={MOVIE_ASPECT_RATIO}>
                    <Image
                      src={result?.movie.coverImage}
                      alt={result?.movie.cid}
                      sx={{
                        objectFit: 'cover',
                      }}
                    />
                  </AspectRatio>
                </Box>

                <Divider sx={{my: [2]}} />
              </>
            )}

            {result?.movie.actors.map((item) => (
              <Box key={`actors-${item.id}`}>
                <Flex
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxW: 'md',
                    px: [4, 0],
                  }}
                >
                  <Link to={`/actors/${item.id}`}>
                    <Text sx={{textTransform: 'capitalize'}}>{item.name}</Text>
                  </Link>
                  {session?.user?.isAdmin && (
                    <Box sx={{flex: 1, mx: [2]}}>
                      <UpdateCoverImageButton movieId={result.movie.id} id={item.id} />
                    </Box>
                  )}
                  <Link to={`/actors/${item.id}`}>
                    <AspectRatio ratio={1} boxSize={['50px']}>
                      <Image
                        borderRadius="full"
                        src={item.coverImage}
                        alt={item.name}
                        sx={{
                          objectPosition: '100% 0%',
                        }}
                      />
                    </AspectRatio>
                  </Link>
                </Flex>
                <Divider sx={{my: [2]}} />
              </Box>
            ))}

            {session?.user?.isAdmin && (
              <>
                <HStack>
                  {['th', 'en'].map((language, i) => {
                    const hasSubtitle = (result?.movie.subtitles || []).findIndex((e) => e.language === language) > -1;
                    return (
                      <AddSubtitleButton
                        key={`${i}-${language}`}
                        language={language}
                        movieId={movieId}
                        hasSubtitle={hasSubtitle}
                        onCompleted={refetch}
                      />
                    );
                  })}
                </HStack>

                <Divider sx={{my: [2]}} />
              </>
            )}

            {session?.user?.isAdmin && (result?.movie.torrents || []).length > 0 && (
              <TorrentController torrents={result?.movie.torrents || []} onUpdate={() => refetch()} />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={[2, 2, 2, 1]} sx={{py: [6], px: [null, null, 6]}}>
          {result?.movie && <MovieSuggestion items={result.movie.relatedMovies} />}
        </GridItem>
      </Grid>
    </Container>
  );
};
