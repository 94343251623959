import {useMutation} from '@apollo/client';
import {IconButton, useToast} from '@chakra-ui/react';
import React from 'react';
import {FiArrowUp} from 'react-icons/fi';
import {AddMovieToGroupResult, AddMovieToGroupVars, ADD_MOVIE_TO_GROUP} from '../../graph/torrent';

const groupName = 'JAVBUS';

export const SearchTorrentButton = ({movieId}: {movieId: number}) => {
  const toast = useToast();
  const [searchTorrent] = useMutation<AddMovieToGroupResult, AddMovieToGroupVars>(ADD_MOVIE_TO_GROUP, {
    variables: {movieId, groupName},
    onCompleted: () => {
      toast({title: 'Queued up torrent'});
    },
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
  });

  return <IconButton icon={<FiArrowUp />} variant="ghost" aria-label="like" onClick={() => searchTorrent()} />;
};
