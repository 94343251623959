import {gql} from '@apollo/client';
import {Actor} from './actor';
import {Code} from './code';
import {Genre} from './genre';
import {Maker} from './maker';
import {Torrent} from './torrent';
import {Connection, Node, OrderDirection, Video} from './types';

export type Movie = Node & {
  cid: string;
  releasedAt: string;
  title: string;
  coverImage: string;
  isLiked: boolean;
  isPro: boolean;
  hasVideo: boolean;
  hasSubtitle: boolean;
  hasTorrent: boolean;
  source: string;
  actors: Actor[];
  genres: Genre[];
  code: Code;
  maker: Maker;
  videos: Video[];
  torrents: Torrent[];
  subtitles: Subtitle[];
  relatedMovies: Movie[];
  videoViewCount: number;
};

export type Subtitle = Node & {
  language: string;
};

export const movieFragment = gql`
  fragment movieFields on Movie {
    id
    cid
    releasedAt
    title
    coverImage
    isLiked
    isPro
    hasVideo
    hasSubtitle
    hasTorrent
    source
    actors {
      id
      name
      coverImage
    }
    genres {
      id
      name
    }
    maker {
      id
      name
    }
    code {
      id
      name
    }
    videos {
      id
      uid
      cid
      url
      source
      isPro
    }
    torrents {
      id
      name
      status
      length
      size
      date
    }
    subtitles {
      language
    }
    relatedMovies {
      id
      coverImage
      cid
      releasedAt
    }
    videoViewCount
  }
`;

export const movieOffsetConnectionFragment = gql`
  ${movieFragment}

  fragment movieOffsetConnectionFields on MovieOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...movieFields
      }
    }
  }
`;

export enum MovieOrderField {
  RELEASED_AT = 'RELEASED_AT',
  VIDEO_VIEW_COUNT = 'VIDEO_VIEW_COUNT',
}

export type MovieOrder = {
  field: MovieOrderField;
  direction: OrderDirection;
};

export type WhereMovie = {
  hasVideo?: boolean;
  isPro?: boolean;
  hasSubtitle?: boolean;
  hasTorrent?: boolean;
  isDownloading?: boolean;
  query?: string;
  isSolo?: boolean;
  isLiked?: boolean;
  releasedAtBefore?: Date;
  releasedAtAfter?: Date;
  page?: number;
  itemPerPage?: number;
  actorId?: number;
  genreId?: number;
  makerId?: number;
  codeId?: number;
};

export type ListMoviesResult = {
  movies: Connection<Movie>;
};

export type ListMoviesVars = {
  orderBy?: MovieOrder;
  where?: WhereMovie;
};

export const LIST_MOVIES = gql`
  query movies($orderBy: MovieOrder, $where: WhereMovie) {
    movies: movies(orderBy: $orderBy, where: $where) {
      ...movieOffsetConnectionFields
    }
  }
  ${movieOffsetConnectionFragment}
`;

export type GetMovieResult = {
  movie: Movie;
};

export type GetMovieVars = {
  id: number;
};

export const GET_MOVIE = gql`
  query movies($id: ID!) {
    movie: movie(id: $id) {
      ...movieFields
    }
  }
  ${movieFragment}
`;

export type SuggestMoviesResult = {
  movies: Connection<Movie>;
};

export type SuggestMoviesVars = {
  id: number;
  where?: WhereMovie;
};

export const SUGGEST_MOVIES = gql`
  query movies($id: ID!, $where: WhereMovie) {
    movies: suggestMovies(id: $id, where: $where) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPage
        nextPage
      }
      edges {
        cursor
        node {
          id
          cid
          coverImage
          releasedAt
        }
      }
    }
  }
`;

export type LikeMovieResult = {
  movie: Movie;
};

export type LikeMovieVars = {
  id: number;
};

export const LIKE_MOVIE = gql`
  mutation likeMovie($id: ID!) {
    movie: likeMovie(id: $id) {
      id
      isLiked
    }
  }
`;

export type UnlikeMovieResult = {
  movie: Movie;
};

export type UnlikeMovieVars = {
  id: number;
};

export const UNLIKE_MOVIE = gql`
  mutation unlikeMovie($id: ID!) {
    movie: unlikeMovie(id: $id) {
      id
      isLiked
    }
  }
`;

export type SearchVideoResult = {
  Videos: Video;
};

export type SearchVideoVars = {
  movieId: number;
};

export const SEARCH_VIDEO = gql`
  mutation searchVideo($movieId: ID!) {
    Videos: searchVideo(movieId: $movieId) {
      id
    }
  }
`;

export type UpdateCoverImageResult = {
  node: Node;
};

export type UpdateCoverImageVars = {
  id: number;
  movieId: number;
};

export const UPDATE_COVER_IMAGE = gql`
  mutation updateCoverImage($movieId: ID!, $id: ID!) {
    node: updateCoverImage(movieId: $movieId, id: $id) {
      id
    }
  }
`;

export type AddSubtitleResult = {
  movie: Movie;
};

export type AddSubtitleVars = {
  movieId: number;
  language: string;
};

export const ADD_SUBTITLE = gql`
  mutation addSubtitle($movieId: ID!, $language: String!) {
    movie: addSubtitle(movieId: $movieId, language: $language) {
      id
    }
  }
`;

export type RemoveSubtitleResult = {
  movie: Movie;
};

export type RemoveSubtitleVars = {
  movieId: number;
  language: string;
};

export const REMOVE_SUBTITLE = gql`
  mutation removeSubtitle($movieId: ID!, $language: String!) {
    movie: removeSubtitle(movieId: $movieId, language: $language) {
      id
    }
  }
`;

export type ViewVideoResult = {
  id: number;
};

export type ViewVideoVars = {
  id: number;
};

export const VIEW_VIDEO = gql`
  mutation viewVideo($id: ID!) {
    id: viewVideo(id: $id)
  }
`;
