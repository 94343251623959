import {useMutation} from '@apollo/client';
import {
  Box,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import queryString from 'query-string';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Link, useHistory} from 'react-router-dom';
import {LoginUsernameResult, LoginUsernameVars, LOGIN_USERNAME} from '../../graph/auth';
import {AppDispatch} from '../../state';
import {updateUserSession} from '../../state/user/actions';

export default function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const redirect = (parsed.redirect as string) || undefined;

  const toast = useToast();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [fetchData] = useMutation<LoginUsernameResult, LoginUsernameVars>(LOGIN_USERNAME, {
    variables: {
      username,
      password,
    },
    onCompleted: (data) => {
      if (!data) return;

      dispatch(updateUserSession({session: data.session}));

      if (redirect && redirect !== '') {
        history.push(redirect);
        return;
      }
      history.push('/');
    },
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
  });

  const submit = (e: any) => {
    e.preventDefault();

    fetchData();
  };

  return (
    <Container>
      <Flex sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Heading size="lg" sx={{py: [8]}}>
          Login
        </Heading>

        <Box
          as="form"
          boxShadow="lg"
          p={[6]}
          rounded="md"
          onSubmit={submit}
          sx={{width: ['303px'], bg: 'whiteAlpha.200'}}
        >
          <VStack spacing={[4]}>
            <FormControl id="username">
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                id="username"
                required
                autoFocus
                focusBorderColor="pink.700"
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormHelperText>Your username.</FormHelperText>
            </FormControl>

            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                id="password"
                required
                focusBorderColor="pink.700"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormHelperText>At least 8 charaters</FormHelperText>
            </FormControl>
          </VStack>

          <Input variant="submit" type="submit" value="Login" sx={{mt: [4], bg: 'pink.500'}} />

          <Box sx={{mt: [4]}}>
            <Text variant="caption">Has no account?</Text>{' '}
            <Link to={`/signup${redirect ? `redirect=${redirect}` : ''}`}>
              <Text variant="caption" color="accent.b1000" sx={{cursor: 'pointer'}}>
                signup for free
              </Text>
            </Link>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
