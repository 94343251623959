import {Box, HStack, Tag, TagLabel, VStack} from '@chakra-ui/react';
import React from 'react';
import {Movie} from '../../graph/movie';
import {MovieCardShort} from './MovieCardShort';

export default function MovieSuggestion({items}: {items?: Movie[]}) {
  return (
    <Box>
      <HStack spacing={[4]} sx={{overflowX: 'auto'}}>
        <Tag colorScheme="pink">
          <TagLabel>All</TagLabel>
        </Tag>
        <Tag colorScheme="pink">
          <TagLabel>Same Actor</TagLabel>
        </Tag>
        <Tag colorScheme="pink">
          <TagLabel>Same Genre</TagLabel>
        </Tag>
      </HStack>

      <VStack spacing={[4]} sx={{py: [4]}}>
        {items && items.map((item, id) => <MovieCardShort item={item} key={id} />)}
      </VStack>
    </Box>
  );
}
