import {configureStore} from '@reduxjs/toolkit';
import {load, save} from 'redux-localstorage-simple';
import application from './application/reducer';
import movies from './movies/reducer';
import user from './user/reducer';

const PERSISTED_KEYS: string[] = ['user'];

const store = configureStore({
  reducer: {user, movies, application},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false}).concat(
      save({namespace: 'warp', states: PERSISTED_KEYS, disableWarnings: true})
    ),
  preloadedState: load({namespace: 'warp', states: PERSISTED_KEYS, disableWarnings: true}),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
