import {Center, Text} from '@chakra-ui/layout';
import React from 'react';
import {MovieList} from '../components/Movie/MovieList';
import {useSessionManager} from '../state/user/hooks';

export default function Torrents() {
  const {session} = useSessionManager();
  if (!session?.user?.putioAccessToken) {
    return (
      <Center width="100%" height="50vh">
        <Text>You need to be PRO to access this page</Text>
      </Center>
    );
  }

  return <MovieList where={{hasTorrent: true}} />;
}
