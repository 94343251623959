import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, AppState} from '../index';
import {ApplicationModal, setOpenModal, setSidebarSize} from './actions';

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal);
  return openModal === modal;
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal);
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open]);
}

export function useToggleSidebarSize(): ['small' | 'large', () => void] {
  const sidebarSize = useSelector((state: AppState) => state.application.sidebarSize);
  const dispatch = useDispatch<AppDispatch>();
  return [
    sidebarSize,
    useCallback(() => dispatch(setSidebarSize(sidebarSize === 'small' ? 'large' : 'small')), [dispatch, , sidebarSize]),
  ];
}
