export const NAVBAR_HEIGH = ['56px', '64px'];
export const MOVIE_ASPECT_RATIO = 16 / 9;
export const MOVIE_ASPECT_RATIO_HALF = 9 / 16;
export const CARD_PREVIEW_MAX_WIDTH = ['300px'];
export const ACTOR_CARD_PREVIEW_MAX_WIDTH = ['200px'];
export const CARD_PREVIEW_MAX_WIDTH_HALF = ['150px'];
export const MOVIE_ITEM_PER_PAGE = 12;
export const ACTOR_ITEM_PER_PAGE = 24;
export const GENRE_ITEM_PER_PAGE = 24;
export const MAKER_ITEM_PER_PAGE = 24;
export const CODE_ITEM_PER_PAGE = 24;
