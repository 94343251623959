import {useQuery} from '@apollo/client';
import {Flex} from '@chakra-ui/layout';
import React from 'react';
import {useParams} from 'react-router';
import {ActorCardPreivew} from '../../components/Actor/ActorCardPreview';
import {MovieList} from '../../components/Movie/MovieList';
import {GetActorResult, GetActorVars, GET_ACTOR} from '../../graph/actor';

export default function ActorId() {
  const {id} = useParams<{id: string}>();
  const actorId = parseInt(id);

  const {data: result} = useQuery<GetActorResult, GetActorVars>(GET_ACTOR, {
    variables: {
      id: actorId,
    },
  });

  const hero = (
    <Flex>
      <ActorCardPreivew item={result?.actor} />
    </Flex>
  );

  return <MovieList where={{actorId}} hero={hero} nodeId={actorId} />;
}
