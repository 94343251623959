import {gql} from '@apollo/client';
import {Connection, Node, OrderDirection} from './types';

export type Genre = Node & {
  name: string;
  coverImage: string;
};

export enum GenreOrderField {
  LATEST_RELEASED_AT = 'LATEST_RELEASED_AT',
  NAME = 'NAME',
}

export type GenreOrder = {
  field: GenreOrderField;
  direction: OrderDirection;
};

export type WhereGenre = {
  query?: string;
  page?: number;
  itemPerPage?: number;
};

export const genreFragment = gql`
  fragment genreFields on Genre {
    id
    name
    coverImage
  }
`;

export const genreOffsetConnectionFragment = gql`
  ${genreFragment}

  fragment genreOffsetConnectionFields on GenreOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...genreFields
      }
    }
  }
`;

export type ListGenresResult = {
  genres: Connection<Genre>;
};

export type ListGenresVars = {
  orderBy?: GenreOrder;
  where?: WhereGenre;
};

export const LIST_GENRES = gql`
  query genres($orderBy: GenreOrder, $where: WhereGenre) {
    genres: genres(orderBy: $orderBy, where: $where) {
      ...genreOffsetConnectionFields
    }
  }
  ${genreOffsetConnectionFragment}
`;

export type GetGenreResult = {
  genre: Genre;
};

export type GetGenreVars = {
  id: number;
};

export const GET_GENRE = gql`
  query genre($id: ID!) {
    genre: genre(id: $id) {
      ...genreFields
    }
  }
  ${genreFragment}
`;
