import React from 'react';
import {useParams} from 'react-router-dom';
import {MovieItem} from '../../components/Movie/MovieItem';

export default function MovieId() {
  const {id} = useParams<{id: string}>();
  const movieId = parseInt(id);

  return <MovieItem movieId={movieId} />;
}
