import {useMutation} from '@apollo/client';
import {IconButton, useToast} from '@chakra-ui/react';
import React from 'react';
import {FiCornerDownRight} from 'react-icons/fi';
import {SearchVideoResult, SearchVideoVars, SEARCH_VIDEO} from '../../graph/movie';

export const SearchVideoButton = ({movieId, onCompleted}: {movieId: number; onCompleted?: () => void}) => {
  const toast = useToast();
  const [searchVideo] = useMutation<SearchVideoResult, SearchVideoVars>(SEARCH_VIDEO, {
    variables: {movieId},
    onCompleted: () => {
      toast({title: 'Fetched videos'});
      if (onCompleted) onCompleted();
    },
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
  });

  return <IconButton icon={<FiCornerDownRight />} variant="ghost" aria-label="search" onClick={() => searchVideo()} />;
};
