import {useMutation} from '@apollo/client';
import {Box, Button, Divider, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast} from '@chakra-ui/react';
import {fileSize} from 'humanize-plus';
import React from 'react';
import {
  CleanupTorrentResult,
  CleanupTorrentVars,
  CLEANUP_TORRENT,
  DeleteTorrentResult,
  DeleteTorrentVars,
  DELETE_TORRENT,
  DownladTorrentVars,
  DownloadTorrentResult,
  DOWNLOAD_TORRENT,
  RetryTorrentResult,
  RetryTorrentVars,
  RETRY_TORRENT,
  SyncTransferringTorrentResult,
  SyncTransferringTorrentVars,
  SYNC_TRANSFERRING_TORRENT,
  Torrent,
} from '../../graph/torrent';

const torrentLength = (torrent: Torrent): string => {
  return torrent.size || fileSize(torrent.length);
};

type actionType = 'downloadable' | 'downloading' | 'error' | 'complete' | 'unknown';

const getAction = (status: string): actionType => {
  switch (status) {
    case 'UNDEFINED':
      return 'downloadable';
    case 'WAITING':
    case 'IN_QUEUE':
    case 'PREPARING_DOWNLOAD':
    case 'DOWNLOADING':
      return 'downloading';
    case 'ERROR':
      return 'error';
    case 'COMPLETED':
    case 'SEEDING':
    case 'COMPLETING':
      return 'complete';
    default:
      return 'unknown';
  }
};

export const TorrentController = ({
  torrents,
  onUpdate,
}: {
  torrents: Torrent[];
  onUpdate: (torrent?: Torrent) => void;
}) => {
  const toast = useToast();
  // const hasDownloadingTorrent = torrents.filter((t) => getAction(t.status) === 'downloading').length > 0;

  const [syncTransferringTorrent, {loading: isLoading}] = useMutation<
    SyncTransferringTorrentResult,
    SyncTransferringTorrentVars
  >(SYNC_TRANSFERRING_TORRENT, {
    onCompleted: () => {
      onUpdate();
    },
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
  });

  return (
    <>
      <Box>
        <Box sx={{px: [4, 0], py: [2]}}>
          <Button width={['100%']} isLoading={isLoading} onClick={() => syncTransferringTorrent()}>
            Sync Torrent
          </Button>
        </Box>

        <Divider sx={{my: [2]}} />
      </Box>

      <Box sx={{overflowX: 'auto'}}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Size</Th>
              <Th>Status</Th>
              <Th sx={{textAlign: 'center'}}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {torrents.map((item, id) => (
              <TorrentItem torrent={item} key={id} onUpdate={onUpdate} />
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

const TorrentItem = ({torrent, onUpdate}: {torrent: Torrent; onUpdate: (torrent?: Torrent) => void}) => {
  const toast = useToast();

  const [download, {loading: downloadLoading}] = useMutation<DownloadTorrentResult, DownladTorrentVars>(
    DOWNLOAD_TORRENT,
    {
      variables: {id: torrent.id},
      onError: (error) => {
        toast({title: 'Someting went wrong', description: error.message, status: 'error'});
      },
      onCompleted: (data) => {
        onUpdate(data.torrent);
      },
    }
  );

  const [cleanup, {loading: cleanupLoading}] = useMutation<CleanupTorrentResult, CleanupTorrentVars>(CLEANUP_TORRENT, {
    variables: {id: torrent.id},
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
    onCompleted: (data) => {
      onUpdate(data.torrent);
    },
  });

  const [retry, {loading: retryLoading}] = useMutation<RetryTorrentResult, RetryTorrentVars>(RETRY_TORRENT, {
    variables: {id: torrent.id},
    onError: (error) => {
      toast({title: 'Someting went wrong', description: error.message, status: 'error'});
    },
    onCompleted: (data) => {
      onUpdate(data.torrent);
    },
  });

  const [deleteTorrent, {loading: deleteLoading}] = useMutation<DeleteTorrentResult, DeleteTorrentVars>(
    DELETE_TORRENT,
    {
      variables: {id: torrent.id},
      onError: (error) => {
        toast({title: 'Someting went wrong', description: error.message, status: 'error'});
      },
      onCompleted: () => {
        onUpdate();
      },
    }
  );

  const action = getAction(torrent.status);

  return (
    <Tr>
      <Td>
        <Text maxWidth="100px" isTruncated>
          {torrent.name}
        </Text>
      </Td>
      <Td>{torrentLength(torrent)}</Td>
      <Td>{torrent.status}</Td>
      <Td>
        <HStack>
          <Button onClick={() => download()} disabled={action !== 'downloadable'} isLoading={downloadLoading}>
            Download
          </Button>
          <Button
            onClick={() => cleanup()}
            disabled={action !== 'complete' && action !== 'downloading'}
            isLoading={cleanupLoading}
          >
            Cleanup
          </Button>
          <Button onClick={() => retry()} disabled={action !== 'error'} isLoading={retryLoading}>
            Retry
          </Button>
          <Button
            onClick={() => {
              if (!confirm(`Do you want to delete ${torrent.name}`)) return;

              deleteTorrent();
            }}
            isLoading={deleteLoading}
          >
            Delete
          </Button>
        </HStack>
      </Td>
    </Tr>
  );
};
