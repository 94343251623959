import {Button, Center, HStack, IconButton} from '@chakra-ui/react';
import queryString from 'query-string';
import React, {FC} from 'react';
import {FiChevronsLeft, FiChevronsRight} from 'react-icons/fi';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {PageInfo} from '../../graph/types';

export interface PaginationProps {
  pageInfo: PageInfo;
  itemPerPage: number;
  currentPage?: number;
  pageCount: number;
}

export const Pagination: FC<PaginationProps> = ({pageInfo: {totalPage}, itemPerPage, currentPage = 1, pageCount}) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const offset = Math.floor((currentPage - 1) / pageCount);
  const lower = offset * pageCount;
  const upper = (offset + 1) * pageCount > totalPage ? totalPage : (offset + 1) * pageCount;

  const pages = Array(upper - lower)
    .fill(1)
    .map((x, i) => {
      const page = offset * pageCount + x + i;
      return (
        <Link to={{pathname: location.pathname, search: queryString.stringify({...parsed, page, itemPerPage})}} key={i}>
          <Button variant={currentPage === page ? 'solid' : 'outline'} fontSize={['xs']}>
            {page}
          </Button>
        </Link>
      );
    });

  return (
    <Center>
      <HStack sx={{px: [0, 4, 12], py: [10]}}>
        {lower > 0 && (
          <Link
            to={{pathname: location.pathname, search: queryString.stringify({...parsed, page: lower, itemPerPage})}}
          >
            <IconButton variant="outline" fontSize="20px" aria-label="go to next page" icon={<FiChevronsLeft />} />
          </Link>
        )}

        {totalPage > 1 && pages}

        {upper >= (offset + 1) * pageCount && (
          <Link
            to={{pathname: location.pathname, search: queryString.stringify({...parsed, page: upper + 1, itemPerPage})}}
          >
            <IconButton variant="outline" fontSize="20px" aria-label="go to next page" icon={<FiChevronsRight />} />
          </Link>
        )}
      </HStack>
    </Center>
  );
};
