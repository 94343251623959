import {useMutation} from '@apollo/client';
import {Button} from '@chakra-ui/react';
import React from 'react';
import {
  AddSubtitleResult,
  AddSubtitleVars,
  ADD_SUBTITLE,
  RemoveSubtitleResult,
  RemoveSubtitleVars,
  REMOVE_SUBTITLE,
} from '../../graph/movie';

export const AddSubtitleButton = ({
  movieId,
  hasSubtitle,
  language,
  onCompleted,
}: {
  movieId: number;
  language: string;
  hasSubtitle: boolean;
  onCompleted?: () => void;
}) => {
  const [addSubtitle, {loading: addLoading}] = useMutation<AddSubtitleResult, AddSubtitleVars>(ADD_SUBTITLE, {
    variables: {movieId, language},
    onCompleted,
  });
  const [removeSubtitle, {loading: removeLoading}] = useMutation<RemoveSubtitleResult, RemoveSubtitleVars>(
    REMOVE_SUBTITLE,
    {
      variables: {movieId, language},
      onCompleted,
    }
  );

  return (
    <Button
      variant={hasSubtitle ? 'solid' : 'outline'}
      colorScheme="pink"
      width="120px"
      isLoading={addLoading || removeLoading}
      onClick={() => {
        hasSubtitle ? removeSubtitle() : addSubtitle();
      }}
    >
      {hasSubtitle ? 'Remove ' : 'Add '}
      {language.toUpperCase()}
    </Button>
  );
};
