export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalPage: number;
  nextPage: number;
};

export type Edge<T> = {
  cursor: string;
  node: T;
};

export type Connection<T> = {
  totalCount: number;
  pageInfo: PageInfo;
  edges: Edge<T>[];
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Node = {
  id: number;
};

export type Video = {
  id: number;
  uid: string;
  cid: string;
  url: string;
  source: string;
  isPro: string;
};

export type Subtitle = {
  language: string;
};
