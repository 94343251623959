import React from 'react';
import 'video.js/dist/video-js.css';
import {Video} from '../../graph/types';
import {VideoJS} from './VideoJs';

export const VideoPlayer = ({video}: {video: Video}) => {
  switch (video.source) {
    case 'AVGLE':
      return <iframe width="100%" height="100%" src={video.url} frameBorder="0" allowFullScreen></iframe>;

    case 'PUT':
      return <VideoJS video={video} />;

    default:
      return null;
  }
};
