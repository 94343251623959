import {createReducer} from '@reduxjs/toolkit';
import {ApplicationModal, setOpenModal, setSidebarSize} from './actions';

export interface ApplicationState {
  readonly openModal: ApplicationModal | null;
  readonly sidebarSize: 'small' | 'large';
}

const initialState: ApplicationState = {
  openModal: null,
  sidebarSize: 'small',
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setOpenModal, (state, action) => {
      state.openModal = action.payload;
    })
    .addCase(setSidebarSize, (state, action) => {
      state.sidebarSize = action.payload;
    });
});
