import {Center, Text} from '@chakra-ui/layout';
import React from 'react';
import {MovieList} from '../components/Movie/MovieList';
import {useSessionManager} from '../state/user/hooks';

export default function Like() {
  const {session} = useSessionManager();
  if (!session?.user) {
    return (
      <Center width="100%" height="50vh">
        <Text>You need to login to access this page</Text>
      </Center>
    );
  }

  return <MovieList where={{isLiked: true}} />;
}
