import {useMutation} from '@apollo/client';
import {IconButton, useToken} from '@chakra-ui/react';
import queryString from 'query-string';
import React from 'react';
import {FiThumbsUp} from 'react-icons/fi';
import {useHistory, useLocation} from 'react-router';
import {
  LikeMovieResult,
  LikeMovieVars,
  LIKE_MOVIE,
  UnlikeMovieResult,
  UnlikeMovieVars,
  UNLIKE_MOVIE,
} from '../../graph/movie';
import {useSession} from '../../state/user/hooks';

export const LikeButton = ({
  movieId,
  isLiked,
  onCompleted,
}: {
  movieId: number;
  isLiked: boolean;
  onCompleted?: () => void;
}) => {
  const session = useSession();
  const location = useLocation();
  const history = useHistory();
  const [likeMovie] = useMutation<LikeMovieResult, LikeMovieVars>(LIKE_MOVIE, {
    variables: {id: movieId},
    onCompleted,
  });
  const [unlikeMovie] = useMutation<UnlikeMovieResult, UnlikeMovieVars>(UNLIKE_MOVIE, {
    variables: {id: movieId},
    onCompleted,
  });

  const [fill] = useToken('colors', ['pink.500']);

  const redirect = `${location.pathname}${location.search ? `?${location.search}` : ''}`;

  return (
    <IconButton
      icon={<FiThumbsUp fill={isLiked ? fill : 'none'} />}
      variant="ghost"
      aria-label="like"
      onClick={() => {
        if (!session) {
          history.push({
            pathname: '/login',
            search: queryString.stringify({redirect}),
          });
          return;
        }
        isLiked ? unlikeMovie() : likeMovie();
      }}
    />
  );
};
