import {useQuery} from '@apollo/client';
import {Flex} from '@chakra-ui/layout';
import React from 'react';
import {useParams} from 'react-router';
import {MakerCardPreivew} from '../../components/Maker/MakerCardPreview';
import {MovieList} from '../../components/Movie/MovieList';
import {GetMakerResult, GetMakerVars, GET_MAKER} from '../../graph/maker';

export default function MakerId() {
  const {id} = useParams<{id: string}>();
  const makerId = parseInt(id);

  const {data: result} = useQuery<GetMakerResult, GetMakerVars>(GET_MAKER, {
    variables: {
      id: makerId,
    },
  });

  const hero = (
    <Flex>
      <MakerCardPreivew item={result?.maker} />
    </Flex>
  );

  return <MovieList where={{makerId}} hero={hero} nodeId={makerId} />;
}
