import {Flex, Text} from '@chakra-ui/layout';
import React from 'react';
import {FiFrown} from 'react-icons/fi';

export const NotFound = () => {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        // width: '100%',
        // height: '100%',
        // transform: 'translate(50%, 50%)',
      }}
    >
      <Text sx={{pr: [4]}}>Not Found</Text>
      <FiFrown size="24px" />
    </Flex>
  );
};
