import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  LinkBox,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {Link} from 'react-router-dom';
import {MOVIE_ASPECT_RATIO} from '../../constant';
import {Movie} from '../../graph/movie';
import {computeDate} from '../../utils';

export const MovieCardShort = ({item}: {item?: Movie}) => {
  return (
    <Grid
      width="100%"
      templateRows={['repeat(3, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)']}
      templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)', 'repeat(5, 1fr)']}
      gridGap={[0]}
    >
      <GridItem rowSpan={[2, 1, 1, 1]} colSpan={[2, 1, 1, 3]}>
        <LinkBox as="article">
          <Skeleton width="100%" isLoaded={!!item}>
            <Link to={{pathname: `/movies/${item?.id}`}}>
              <AspectRatio ratio={MOVIE_ASPECT_RATIO} sx={{bg: 'black'}}>
                <Image src={item?.coverImage} alt={item?.cid} objectFit="cover" />
              </AspectRatio>
            </Link>
          </Skeleton>
        </LinkBox>
      </GridItem>
      <GridItem rowSpan={[1, 1, 1, 1]} colSpan={[2, 2, 2, 2]}>
        <Box sx={{px: [4]}}>
          <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mt: [2]}}>
            <SkeletonText isLoaded={!!item} width="100%" noOfLines={1}>
              <Heading size="md">{item?.cid}</Heading>
            </SkeletonText>
          </Flex>
          <Flex textStyle="movieMeta" sx={{flexDirection: 'column'}} display={[null, null, null, 'none', 'flex']}>
            <SkeletonText isLoaded={!!item} width="100%" noOfLines={2}>
              <Text>{computeDate(item?.releasedAt)}</Text>
            </SkeletonText>
          </Flex>
        </Box>
      </GridItem>
    </Grid>
  );
};
